import request from '@/utils/request'
import requsesti from '@/utils/requesti'
import store from '@/store'

// 获取token
export const UserApi = (data) => {
  return request({
    headers: { 'Content-Type': 'multipart/form-data' },
    url: '/oauth/2.0/token',
    method: 'post',
    data
  })
}

// 高精度通用文字识别
export const UrlTextApi = (data) => {
  return request({
    headers: { 'Content-Type': 'multipart/form-data' },
    url: '/rest/2.0/ocr/v1/accurate_basic',
    method: 'post',
    params: { access_token: store.state.token },
    data
  })
}

// 高精度带位置的通用文字识别
export const UploadApi = (data) => {
  return request({
    headers: { 'Content-Type': 'multipart/form-data' },
    url: '/rest/2.0/ocr/v1/accurate',
    method: 'post',
    params: { access_token: store.state.token },
    data
  })
}

// /rest/2.0/ocr/v1/general_basic
// 标准版的通用文字识别
export const UpApi = (data) => {
  return request({
    headers: { 'Content-Type': 'multipart/form-data' },
    url: '/rest/2.0/ocr/v1/general_basic',
    method: 'post',
    params: { access_token: store.state.token },
    data
  })
}
// 标准带位置版的通用文字识别
// /rest/2.0/ocr/v1/general
export const LoadApi = (data) => {
  return request({
    headers: { 'Content-Type': 'multipart/form-data' },
    url: '/rest/2.0/ocr/v1/general',
    method: 'post',
    params: { access_token: store.state.token },
    data
  })
}

// 识别数字
// /rest/2.0/ocr/v1/numbers
export const numberApi = (data) => {
  return request({
    headers: { 'Content-Type': 'multipart/form-data' },
    url: '/rest/2.0/ocr/v1/numbers',
    method: 'post',
    params: { access_token: store.state.token },
    data
  })
}

// 识别二维码
export const cardApi = (data) => {
  return request({
    headers: { 'Content-Type': 'multipart/form-data' },
    url: '/rest/2.0/ocr/v1/qrcode',
    method: 'post',
    params: { access_token: store.state.token },
    data
  })
}
// 识别身份证
export const idcardApi = (data) => {
  return request({
    headers: { 'Content-Type': 'multipart/form-data' },
    url: '/rest/2.0/ocr/v1/idcard',
    method: 'post',
    params: { access_token: store.state.token },
    data
  })
}

// 识别银行卡
// /rest/2.0/ocr/v1/bankcard
export const bankcardApi = (data) => {
  return request({
    headers: { 'Content-Type': 'multipart/form-data' },
    url: '/rest/2.0/ocr/v1/bankcard',
    method: 'post',
    params: { access_token: store.state.token },
    data
  })
}
// 识别车牌
export const carcardApi = (data) => {
  return request({
    headers: { 'Content-Type': 'multipart/form-data' },
    url: '/rest/2.0/ocr/v1/license_plate',
    method: 'post',
    params: { access_token: store.state.token },
    data
  })
}

// 识别行驶证
export const drivecardApi = (data) => {
  return request({
    headers: { 'Content-Type': 'multipart/form-data' },
    url: '/rest/2.0/ocr/v1/vehicle_license',
    method: 'post',
    params: { access_token: store.state.token },
    data
  })
}

// 创建用户组
export const AddGroupApi = (data) => {
  return request({
    headers: { 'Content-Type': 'multipart/form-data' },
    url: '/rest/2.0/face/v3/faceset/group/add',
    method: 'post',
    params: { access_token: store.state.token },
    data
  })
}

// 获取用户组列表
// rest/2.0/face/v3/faceset/group/getlist
export const GetGroupApi = () => {
  return request({
    headers: { 'Content-Type': 'multipart/form-data' },
    url: '/rest/2.0/face/v3/faceset/group/getlist',
    method: 'post',
    params: { access_token: store.state.token }
  })
}
// 删除用户组
export const RemoveGroupApi = (data) => {
  return request({
    headers: { 'Content-Type': 'multipart/form-data' },
    url: '/rest/2.0/face/v3/faceset/group/delete',
    method: 'post',
    params: { access_token: store.state.token },
    data
  })
}

// 删除自己接口的用户组
export const RemoveIGroupApi = (data) => {
  return requsesti({
    headers: { 'Content-Type': 'multipart/form-data' },
    url: '/user-faces/deleteGroup',
    method: 'delete',
    // params: { access_token: store.state.token },
    data
  })
}

// 增加用户
export const AddUserApi = (data) => {
  return request({
    headers: { 'Content-Type': 'application/json' },
    url: '/rest/2.0/face/v3/faceset/user/add',
    method: 'post',
    params: { access_token: store.state.token },
    data
  })
}

// 自己接口增加用户
export const AddIUserApi = (data) => {
  return requsesti({
    headers: { 'Content-Type': 'multipart/form-data' },
    url: '/user-faces/insertFaceInfo',
    method: 'post',
    // params: { access_token: store.state.token },
    data
  })
}
// 获取用户列表
export const GetUserApi = (data) => {
  return request({
    headers: { 'Content-Type': 'multipart/form-data' },
    url: '/rest/2.0/face/v3/faceset/group/getusers',
    method: 'post',
    params: { access_token: store.state.token },
    data
  })
}

// 删除用户
export const RemoveUserApi = (data) => {
  return request({
    headers: { 'Content-Type': 'multipart/form-data' },
    url: '/rest/2.0/face/v3/faceset/user/delete',
    method: 'post',
    params: { access_token: store.state.token },
    data
  })
}
// 删除自己接口的用户   /user-faces/deleteUserId
export const RemoveIUserApi = (data) => {
  return requsesti({
    headers: { 'Content-Type': 'multipart/form-data' },
    url: '/user-faces/deleteUserId',
    method: 'delete',
    // params: { access_token: store.state.token },
    data
  })
}
// 人脸搜索
export const SearchUserApi = (data) => {
  return request({
    headers: { 'Content-Type': 'application/json' },
    url: '/rest/2.0/face/v3/search',
    method: 'post',
    params: { access_token: store.state.token },
    data
  })
}

// 查看用户信息
export const GetUserinfoApi = (data) => {
  return request({
    headers: { 'Content-Type': 'multipart/form-data' },
    url: '/rest/2.0/face/v3/faceset/user/get',
    method: 'post',
    params: { access_token: store.state.token },
    data
  })
}

// 更改用户信息
export const EditUserinfoApi = (data) => {
  return request({
    headers: { 'Content-Type': 'application/json' },
    url: '/rest/2.0/face/v3/faceset/user/update',
    method: 'post',
    params: { access_token: store.state.token },
    data
  })
}

// /user-faces/updateUser

export const EditIUserinfoApi = (data) => {
  return requsesti({
    headers: { 'Content-Type': 'multipart/form-data' },
    url: '/user-faces/updateUser',
    method: 'post',
    // params: { access_token: store.state.token },
    data
  })
}

// 自己接口获取用户信息
export const GetIUserinfoApi = (userId, groupId) => {
  return requsesti({
    headers: { 'Content-Type': 'application/json' },
    url: '/user-faces/getFaceImagePath',
    method: 'get',
    // params: { access_token: store.state.token },
    params: { userId, groupId }
  })
}
