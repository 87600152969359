import Vue from 'vue'
import Vuex from 'vuex'
import { UserApi } from '@/api/user'
import { GetToken, SetToken, RemoveToken } from '@/utils/auth'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    token: GetToken() || ''
  },
  getters: {
  },
  mutations: {
    setToken (state, newToken) {
      state.token = newToken
      SetToken(newToken)
    },
    removeToken (state) {
      state.token = ''
      RemoveToken()
    }
  },
  actions: {
    async login (context) {
      const res = await UserApi({
        grant_type: 'client_credentials',
        client_id: '7ycO4icy1bXPWXCSWxIfH9KO',
        client_secret: '0wuhAimlgIF99hTOGwuO4pDM01elF7Bg'
      })
      const token = res.access_token
      context.commit('setToken', token)
      return res
    }
  },
  modules: {
  }
})
