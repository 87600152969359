// 路由拦截
// eslint-disable-next-line
import router from '@/router'
import store from '@/store'
// eslint-disable-next-line

router.beforeEach(async (to, from, next) => {
  const token = store.state.token
  if (token) {
    next()
  } else {
    store.dispatch('login').then(() => { next() })
  }
})
