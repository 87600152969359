import router from '@/router'
import store from '@/store'
import axios from 'axios'
import { Message } from 'element-ui'

const request = axios.create({
  // headers:{ 'Content-Type': 'application/x-www-form-urlencoded'},
  baseURL: 'baiduApi'
  // timeout: 10000

})

// 添加请求拦截器
request.interceptors.request.use(function (config) {
  // 在发送请求之前做些什么
  // const token = store.state.token
  // if (token) {
  // config.headers.access_token = token
  // }
  return config
}, function (error) {
  // 对请求错误做些什么
  return Promise.reject(error)
})

// 添加响应拦截器
request.interceptors.response.use(function (response) {
  // 2xx 范围内的状态码都会触发该函数。
  // 对响应数据做点什么
  // console.log(response)
  const res = response.data
  if (res.code === 400) {
    // console.log(response)
    // console.log(res)
    Message.error(res.message)
    return Promise.reject(res.message)
  }
  console.log(res)
  if (res.error_code === 110) {
    console.log(1)
    store.commit('removeToken')
  }
  return res
}, function (error) {
  // 超出 2xx 范围的状态码都会触发该函数。
  // 对响应错误做点什么
  if (error.response && error.response.status === 401) {
    router.push('/login')
  }
})

export default request
