import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/home',
    component: () => import('../views/layout.vue'),
    children: [
      {
        name: 'home',
        path: '/home',
        component: () => import('../views/ImageText.vue')
      }, {
        name: 'number',
        path: '/number',
        component: () => import('../views/ImageNumber.vue')
      }, {
        name: 'card',
        path: '/card',
        component: () => import('../views/ImageCard.vue')
      }, {
        name: 'idcard',
        path: '/idcard',
        component: () => import('../views/IdCard.vue')
      }, {
        name: 'bankcard',
        path: '/bankcard',
        component: () => import('../views/BankCard.vue')
      }, {
        name: 'drivecard',
        path: '/drivecard',
        component: () => import('../views/DriveCard.vue')
      }, {
        name: 'carcard',
        path: '/carcard',
        component: () => import('../views/CarCard.vue')
      }, {
        name: 'text',
        path: '/text',
        component: () => import('../views/TextIndex.vue')
      }, {
        name: 'face',
        path: '/face',
        component: () => import('../views/FaceAI.vue')
      }, {
        path: '/user',
        name: 'user',
        component: () => import('@/views/user.vue')
      }, {
        path: '/userinfo',
        name: '/userinfo',
        component: () => import('@/views/dialog.vue')
      }, {
        path: '/camera',
        name: '/camera',
        component: () => import('@/views/CameraIndex.vue')
      }
    ]
  }
]

const router = new VueRouter({
  routes
})

export default router
